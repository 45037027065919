<template lang="">
  <el-row class="page" style="margin: 0" :gutter="16">
    <el-col :span="6">
			<LeftTop></LeftTop>
			<LeftBottom class=mt16></LeftBottom>
		</el-col>
    <el-col :span="12">
			<CenterTop class="mb16"></CenterTop>
			<MapData class='map' :height="843" :mapindex="0"></MapData>
		</el-col>
    <el-col :span="6">
			<RightTop></RightTop>
			<RightBottom></RightBottom>
		</el-col>
	</el-row>
</template>
<script>
import LeftTop from './LeftTop.vue';
import LeftBottom from './LeftBottom.vue';
import CenterTop from './CenterTop.vue';
import CenterBottom from './CenterBottom.vue';
import RightTop from './RightTop.vue';
import RightBottom from './RightBottom.vue';
import MapData from '@/views/homePage/overview/mapData.vue';

export default {
	components: { MapData, LeftTop, LeftBottom, CenterTop, CenterBottom, RightTop, RightBottom },
	data() {
		return {
			mapheight:50
		}
	},
};
</script>
<style lang="scss" scoped>
</style>

<template>
  <div class="w100 mt16 bordered">
    <div class="title w100">
      <div class="titleWrapper">
        <div class="indicator"></div>
        公共突发事件
      </div>
      <div class="numBoard mr16" v-for="item in numBoardate">
        <div>{{ item.title }}<span :class="item.class">{{ item.value }}</span></div>
      </div>
    </div>
    <BaseEchart :option="option" class="mt8" style=" width: 100%; height: 123px"></BaseEchart>
  </div>
</template>
<script>
import BaseEchart from '@/components/BaseEchart';
import { mapState } from 'vuex';


export default {
  components: { BaseEchart },
  data() {
    return {
      option: null,
      numBoardate:[
      {
        title:'总数',
        value:2065,
        class:'blue'
      },
      {
        title:'特别重大',
        value:340,
        class:'red'
      },
      {
        title:'重大',
        value:260,
        class:'red'
      },
      {
        title:'较大',
        value:120,
        class:'red'
      },
      {
        title:'一般',
        value:200,
        class:'blue'
      },
      {
        title:'未分级',
        value:655,
        class:'blue'
      },
      {
        title:'非突发事件',
        value:490,
        class:'blue'
      },
    ]
    };
  },
  computed: {
		...mapState('mapData', ['addressInfo', 'mapLevel', 'mapDataArr']),
	},
  watch: {
    mapLevel:{
      immediate:true,
      handler: function(){
        this.option = ({
          axisLabel: {
            color: '#fff',
            fontSize: 10
          },
          grid: {
            left: 40,
            right: 40,
            top: 8,
            bottom: 32
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            splitLine: {
              show: true,
              lineStyle: {
                opacity: 0.2,
                type: [5, 5],
                dashOffset: 0,
                shadowBlur: 0
              },
            },
            data: ['传染病', '食物中毒', '职业中毒', '其它中毒事件', '环境卫生', '免疫接种', '群体性不明原因', '医疗机构内感染', '放射性卫生'],
            axisLabel: {
            interval: 0, // 强制显示所有标签
            }
          },
          yAxis: {
            type: 'value',
            splitNumber: 4,
            min: 0,
            max: 280,
            splitLine: {
              show: true,
              lineStyle: {
                opacity: 0.2,
                type: [5, 5],
                dashOffset: 0,
                shadowBlur: 0
              },
            },
          },
          series: [
            {
              data: [90, 100, 200, 100, 110, 280, 150, 260, 160].map(d => {
                return Math.round(d / (this.mapLevel + 1))
              }),
              type: 'line',
              smooth: true,
              symbol: "none",
              lineStyle: {
                color: '#3BABF0',
              },
              areaStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                    offset: 0, color: '#0D3155' // 0% 处的颜色
                  }, {
                    offset: 1, color: 'rgba(0, 124, 190, 0.1)' // 100% 处的颜色
                  }],
                }
              }
            }
          ]
        })
      }
    },
		// mapDataArr() {
    //   let arrData = this.mapDataArr.map((d) => {
		// 		return { name: d.regionName, value: random(0, 1000) };
		// 	});
		// 	this.pieChartOptions = {
		// 		tooltip: {
		// 			trigger: 'axis',
		// 			axisPointer: {
		// 				type: 'shadow',
		// 			},
		// 		},
		// 		grid: {
		// 			left: 10,
		// 			right: 16,
		// 			bottom: 16,
		// 			top: 48,
		// 			containLabel: true,
		// 		},
		// 		xAxis: [
		// 			{
		// 				type: 'category',
		// 				data: arrData.map((item) => item.name),
		// 				axisLine: {
		// 					show: true,
		// 					lineStyle: {
		// 						color: '#063374',
		// 						width: 1,
		// 						type: 'solid',
		// 					},
		// 				},
		// 				axisTick: {
		// 					show: false,
		// 				},
		// 				axisLabel: {
		// 					interval: 0,
		// 					show: true,
		// 					textStyle: {
		// 						color: '#fff',
		// 					},
		// 				},
		// 			},
		// 		],
		// 		yAxis: [
		// 			{
		// 				type: 'value',
		// 				name: '(数量)',
		// 				nameTextStyle: {
		// 					color: '#fff',
		// 					align: 'right',
		// 				},
    //         min: 0,
    //         max: 1000,
		// 				axisLabel: {
		// 					formatter: '{value}',
		// 					color: '#fff',
		// 				},
		// 				axisTick: {
		// 					show: false,
		// 				},
		// 				axisLine: {
		// 					show: false,
		// 					lineStyle: {
		// 						color: '#00c7ff',
		// 						width: 1,
		// 						type: 'solid',
		// 					},
		// 				},
		// 				splitLine: {
		// 					lineStyle: {
		// 						color: '#063374',
		// 					},
		// 				},
		// 			},
		// 		],
		// 		series: [
		// 			{
		// 				name: `数量`,
		// 				type: 'bar',
		// 				data: arrData.map((v) => v.value),
		// 				barWidth: 16,
		// 				itemStyle: {
		// 					normal: {
		// 						color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
		// 							{
		// 								offset: 0,
		// 								color: '#4AAEFF',
		// 							},
		// 							{
		// 								offset: 1,
		// 								color: '#72E4F3',
		// 							},
		// 						]),
		// 						opacity: 1,
		// 					},
		// 				},
		// 			},
		// 		],
		// 		dataZoom: slider(arrData.length),
		// 	};

    //   this.chinaChartOptions = {
		// 		color: ['#F9A168', '#FBD75B', '#4EF5FF', '#4BFDCF', '#307BE3'],
		// 		tooltip: {
		// 			trigger: 'item',
		// 		},
		// 		graphic: {
		// 			elements: [
		// 				{
		// 					type: 'circle',
		// 					shape: {
		// 						r: 86,
		// 					},
		// 					style: {
		// 						stroke: '#307BE3',
		// 						lineWidth: 1,
		// 					},
		// 					left: 'center',
		// 					top: 'center',
		// 				},
		// 				{
		// 					type: 'circle',
		// 					shape: {
		// 						r: 46,
		// 					},
		// 					style: {
		// 						stroke: '#307BE3',
		// 						lineWidth: 1,
		// 					},
		// 					left: 'center',
		// 					top: 'center',
		// 				},
		// 				{
		// 					type: 'image',
		// 					style: {
		// 						image: require('../assets/pie-bg.jpg'),
		// 						width: 82,
		// 						height: 82,
		// 					},
		// 					left: 'center',
		// 					top: 'center',
		// 				},
		// 			],
		// 		},
		// 		series: [
		// 			{
		// 				type: 'pie',
		// 				radius: ['40%', '65%'],
		// 				itemStyle: {
		// 					borderRadius: 20,
		// 					borderColor: '#020C1D',
		// 					borderWidth: 6,
		// 				},
		// 				labelLine: {
		// 					length: 30,
		// 					length2: 30,
		// 				},
		// 				label: {
		// 					alignTo: 'edge',
		// 					formatter: '{name|{b}}\n{value|{c}}',
		// 					minMargin: 5,
		// 					edgeDistance: 10,
		// 					lineHeight: 15,
		// 					rich: {
		// 						name: {
		// 							fontSize: 14,
		// 						},
		// 						value: {
		// 							fontSize: 11,
		// 							color: '#fff',
		// 						},
		// 					},
		// 				},
		// 				data: this.mapLevel === 0 ? [
		// 					{ value: 2061, name: '省点位' },
		// 					{ value: 3415, name: '区县点位' },
		// 					{ value: 2015, name: '乡镇点位' },
		// 					{ value: 1452, name: '村点位' },
		// 					{ value: 1135, name: '市点位' },
		// 				] : this.mapDataArr.map(d => ({name: d.regionName, value: randomByLevel(this.mapLevel)})),
		// 				emphasis: {
		// 					itemStyle: {
		// 						shadowBlur: 10,
		// 						shadowOffsetX: 0,
		// 						shadowColor: 'rgba(0, 0, 0, 0.5)',
		// 					},
		// 				},
		// 			},
		// 		],
		// 	};

    // },
	},
  mounted() {
    this.option = {
      axisLabel: {
        color: '#fff',
        fontSize: 10,
      },
      grid: {
        left: 40,
        right: 40,
        top: 8,
        bottom: 32,
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        splitLine: {
          show: true,
          lineStyle: {
            opacity: 0.2,
            type: [5, 5],
            dashOffset: 0,
            shadowBlur: 0,
          },
        },
        data: ['传染病', '食物中毒', '职业中毒', '其它中毒事件', '环境卫生', '免疫接种', '群体性不明原因', '医疗机构内感染', '放射性卫生'],
        axisLabel: {
            interval: 0, // 强制显示所有标签
            }
      },
      yAxis: {
        type: 'value',
        splitNumber: 4,
        splitLine: {
          show: true,
          lineStyle: {
            opacity: 0.2,
            type: [5, 5],
            dashOffset: 0,
            shadowBlur: 0,
          },
        },
      },
      series: [
        {
          data: [90, 100, 200, 100, 110, 280, 150, 260, 160],
          type: 'line',
          smooth: true,
          symbol: 'none',
          lineStyle: {
            color: '#3BABF0',
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: '#0D3155', // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: 'rgba(0, 124, 190, 0.1)', // 100% 处的颜色
                },
              ],
            },
          },
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/theme/bigScreen.scss';

.bordered{
  background: rgba(5, 41, 92, .6);
  border: 2px solid rgba(17, 94, 205, .2);
}
.title {
  line-height: 32px;
  padding-left: 16px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: right;

  .titleWrapper {
    display: flex;
    align-items: center;
  }
  .numBoard {
    display: flex;
    gap: 24px;
    font-size: 14px;
    .blue {
      font-weight: bold;
      margin-left: 6px;
      font-size: 16px;
      color: $main-text;
    }
    .red {
      font-weight: bold;
      margin-left: 6px;
      font-size: 16px;
      color: #FF0000;
    }
  }
  .indicator {
    display: inline-block;
    height: 16px;
    width: 4px;
    margin-right: 8px;
    background-image: linear-gradient(#308fcc, #224abe);
  }
}
</style>

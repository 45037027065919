<template >
  <div class="statistics">
    <div class="statistics-item" v-for="(item, index) in statisticsListByLevel" :key="item.label">
			<div class="line" v-if="index != 0"></div>
			<div class="label">{{ item.label }}</div>
			<div :class="(index==0||index==1)?'numyellow':'num'">
				<countTo :startVal="0" :endVal="item.num" :duration="CountAnimationDuration"></countTo>
			</div>
		</div>
  </div>
</template>
<script>
import StatisticsBar from '../components/StatisticsBar.vue'
import { CountAnimationDuration } from '@/views/homePage/common';
import countTo from 'vue-count-to';
import { mapState } from 'vuex'
export default {
  components: { StatisticsBar,countTo },
  data() {
    return {
      CountAnimationDuration,
      statisticsList: [
        { label: '合作收益（元） ', num: 12068 },
        { label: '中医药运营收益（元）', num: 2068 },
        { label: '合作伙伴数', num: 1023 },
        { label: '合作项目数', num: 512 },
        { label: '品牌指标数', num: 2230 },
      ],
    }
  },
  computed: {
		...mapState('mapData', ['mapLevel', 'mapDataArr', 'addressInfo']),
    statisticsListByLevel(){
      return this.statisticsList.map(s => {
        return {
          ...s,
          num: s.num / (this.mapLevel + 1)
        }
      })
    }
	},
}
</script>
<style lang="scss" scoped>
.bordered{
  background: rgba(5, 41, 92, .6);
  border: 2px solid rgba(17, 94, 205, .2);
}
.statistics {
	background: url('../../../assets/imgs/totalBgTop.png') no-repeat center;
	background-size: 100% 100%;
	height: 100px;
	padding: 20px 44px;
	display: flex;
	justify-content: space-between;
	&-item {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
    // flex:1;
    width: auto;

		.label {
			font-size: 16px;
		}
		.num {
			color: #0bf9fe;
			font-weight: bold;
			font-style: italic;
			font-size: 26px;
		}
    .numyellow{
      color: #F5F971;
			font-weight: bold;
			font-style: italic;
			font-size: 26px;
    }
		.line {
			width: 1px;
			height: 29px;
			border: 1px solid #274574;
			position: absolute;
			left: -30px;
			top: 10px;
		}
	}
}</style>